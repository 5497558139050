import { graphql} from "gatsby";
import React, { Fragment } from "react";
import {useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import { Hero } from "../components/Hero/Hero";
import { Card } from "../components/Card/Card";
import { Container } from "../components/Container/Container";
import { Column } from "../components/Column/Column";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;




const CompanyProfile = () => {
  const { t } = useTranslation();

  return (
    <Layout
      pageTitle={t("company-profile.hero.title")}
      description={t("company-profile.hero.subtitle")}
    >
      <Hero
        title={t("company-profile.hero.title")}
        description={t("company-profile.hero.subtitle")}
        image="company-profile/company-profile-hero.jpg"
        btnLink="/contact/"
        btnText={t("global.contact-us")}
        withSpacing
      />
     <Container>
        <Column columns_mobile="12" columns_tablet="12" columns_desktop="12" custom_class="no-gutter@lg">
          <Card
            title={t("company-profile.about-tag.title")}
            description={t("company-profile.about-tag.text")}
            image="company-profile/about-tag.jpg"
          />

          <Card
            title={t("company-profile.corporate-structure.title")}
            description={
              <Fragment>
                <p>
                {t("company-profile.corporate-structure.text1")}
                </p>
                <p>
                {t("company-profile.corporate-structure.text2")}
                </p>
              </Fragment>
            }
            image="company-profile/corporate-structure.jpg"
            reverse
          />
        </Column>
      </Container>
    </Layout>
  );
};

export default CompanyProfile;
